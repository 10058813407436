import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Text } from "rebass";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop"
        }}>{`Welcome to the workshop`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Introduction</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/module-1"
        }}>{`The modern stack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/module-2"
        }}>{`Setting up the basics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/graphql"
        }}>{`Practice GraphQL`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Building your app</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/auth"
        }}>{`configure authentication`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/homepage"
        }}>{`a simple homepage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/dashboard"
        }}>{`user dashboard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/page_editor"
        }}>{`build a page editor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/go_live"
        }}>{`going live`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Before you head out</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshop/outro"
        }}>{`How was it?`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      