import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { navigate } from "gatsby";
import { Button } from "rebass";
import { Container, GumroadOverlay } from "@swizec/gatsby-theme-course-platform";
import Pricing from "../../../components/pricing";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Container width={[1, 3 / 4, 1 / 2]} mb={[4, 4, 5]} sx={{
      textAlign: 'center'
    }} mdxType="Container">
      <h1 {...{
        "id": "hey-it-seems-that-you-dont-have-this"
      }}>{`Hey it seems that you don’t have this!`}</h1>
    </Container>
    <>
      {props.additionalCourse ? <Container width={[1, 3 / 4, 1 / 2]} mb={[4, 4, 5]} sx={{
        textAlign: 'center'
      }} mdxType="Container">
        <p>
            Please purchase {props.title} to access this page. If you already
            purchased, check your email and login top right.
        </p>
        <GumroadOverlay code={props.code} text="Purchase the course" mdxType="GumroadOverlay" />
        <br />
        Cheers
        <br />
        ~Swizec
    </Container> : <>
      {props.GumroadCode ? <Container width={[1, 3 / 4, 1 / 2]} mb={[4, 4, 5]} sx={{
          textAlign: 'center'
        }} mdxType="Container">
          <h2>Upgrade to continue!</h2>
          <GumroadOverlay code={props.GumroadCode} price={props.Price} mdxType="GumroadOverlay" />
        </Container> : <>
          <Container width={[1, 3 / 4, 1 / 2]} mb={[4, 4, 5]} sx={{
            textAlign: 'center'
          }} mdxType="Container">
              <p>
                  Please purchase ServerlessReact.Dev to access this page. If you
                  already purchased, check your email and login top right.
              </p>
          </Container>
          <Pricing mdxType="Pricing" />
        </>}
    </>}
    </>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      