import NavWorkshop from "../../../components/nav-workshop.mdx"
import NavServerlessWorkshop from "../../../components/nav-serverless-workshop.mdx"
import NavJamstackWorkshop from "../../../components/nav-jamstack-workshop.mdx"
import ReactathonWorkshop from "../../../components/nav-reactathon-workshop.mdx"
import TiaWorkshop from "../../../components/tia-workshop.mdx"

export default {
  "/workshop": NavWorkshop,
  "/serverless-workshop": NavServerlessWorkshop,
  "/jamstack-workshop": NavJamstackWorkshop,
  "/reactathon": ReactathonWorkshop,
  "/tia": TiaWorkshop,
}
