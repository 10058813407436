import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Text } from "rebass";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop"
        }}>{`Welcome to the workshop`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Before the workshop</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/intro"
        }}>{`Intro videos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/homework"
        }}>{`Homework setup`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">The workshop</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/hello-world"
        }}>{`Your first JAMStack app`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/design-systems"
        }}>{`Design systems`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/routing"
        }}>{`Routing and pages`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/markdown"
        }}>{`Markdown and MDX for content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/browser-data"
        }}>{`Dynamic data in the browser`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/static-data"
        }}>{`Baked-in static data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/dynamic-static-data"
        }}>{`Dynamic static-first data`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Before you head out</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jamstack-workshop/outro"
        }}>{`How was it?`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      