export default {
    "/module-0": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-1": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-2": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/graphql-practice": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-3": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-3-1": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-3-2": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-3-3": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-3-4": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-3-5": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-4": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-4-1": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-4-2": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-4-3": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-4-4": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/module-4-5": ["Student", "SRD_IndieHacker", "SRD_Professional"],
    "/find-your-idea/*": ["FindYourIdea"],
}