import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Text } from "rebass";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-0"
        }}>{`Welcome to the course`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Introduction</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-1"
        }}>{`The modern stack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-2"
        }}>{`Setting up your app`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/graphql-practice"
        }}>{`GraphQL practice`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Building your app</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-3"
        }}>{`Context`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-3-1"
        }}>{`Your first GraphQL CRUD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-3-2"
        }}>{`Save data from user input`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-3-3"
        }}>{`Create static pages dynamically`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-3-4"
        }}>{`Provisional prod deploy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-3-5"
        }}>{`Questions and answers`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Finishing touches and prod</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-4"
        }}>{`Intro`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-4-1"
        }}>{`List user pages on the dashboard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-4-2"
        }}>{`Deploy webapp on user actions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-4-3"
        }}>{`Get paid with Stripe Checkout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-4-4"
        }}>{`Going to production`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/module-4-5"
        }}>{`Questions and answers`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Before you go</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/outro"
        }}>{`Your feedback ❤️`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Find Your Idea</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/find-your-idea/intro"
        }}>{`Self-paced workshop intro`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/find-your-idea/course"
        }}>{`Find, validate, and qualify ideas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/find-your-idea/worksheet"
        }}>{`Find Your Idea worksheet`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      