import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box, Link } from "rebass";
import { Banner, NavGrid, Container, Grid, LogoGrid, Title, Video } from "@swizec/gatsby-theme-course-platform";
import Pricing from "../../../components/pricing";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Box width={[1, 1, 1 / 2]} sx={{
      mt: 30,
      mx: "auto",
      pb: 120
    }} mdxType="Box">
      <h2 {...{
        "id": "this-content-is-exclusive-for-students"
      }}>{`This content is exclusive for students`}</h2>
      <p>{`Please login to view this page.`}</p>
      <p>{`You can still become a student 👇`}</p>
    </Box>
    <Title h1 mdxType="Title"># ServerlessReact.Dev</Title>
    <Container width={[1, 1, 1 / 2]} mt={[-0.5, -1, -1]} mb={[4, 4, 5]} sx={{
      textAlign: 'center'
    }} mdxType="Container">
      <p>{`Learn how to `}<strong parentName="p">{`build fast modern webapps`}</strong>{` from idea to launch with ServerlessReact.Dev`}</p>
    </Container>
    <Container width={[1, 3 / 4, 1 / 2]} sx={{
      textAlign: 'left'
    }} mdxType="Container">
      <Video id="L8Sx28i2NZw" mdxType="Video" />
      <p>{`ServerlessReact.Dev is a video course that shows you how to build a modern webapp from scratch to launch using the hyper productive modern web stack.`}</p>
      <p>{`You'll learn everything you need to `}<strong parentName="p">{`become a fullstack frontend engineer.`}</strong></p>
      <p>{`ServerlessReact.Dev is focused on practical examples and showing you the whole process. Warts and all.`}</p>
      <p>{`When you follow a polished tutorial, you learn how to build `}<em parentName="p">{`that`}</em>{` thing. But your skills don't transfer. And when something goes wrong, you're stuck.`}</p>
      <p>{`With ServerlessReact.Dev you get a behind-the-scenes look. I'll show you how to debug problems, how to design a solution, where to start, and when to say good enough.`}</p>
      <p>{`Learn `}<strong parentName="p">{`the pragmatic way to build modern apps`}</strong>{`.`}</p>
      <p>{`We start with some theory, learn the stack, do some practical exercises to get a feel for technologies, `}<em parentName="p">{`then build a whole app`}</em>{`. From a list of features to accepting payments.`}</p>
    </Container>
    <Title h2 mdxType="Title">
# What's in ServerlessReact.Dev
    </Title>
    <Container width={[1, 3 / 4, 1 / 2]} sx={{
      textAlign: 'left'
    }} mdxType="Container">
      <p>{`ServerlessReact.Dev covers the exciting new technologies that represent `}<strong parentName="p">{`the future of web development`}</strong>{`. Get ahead of this `}<strong parentName="p">{`exciting new paradigm shift`}</strong>{`.`}</p>
      <p>{`– Learn how to build blazing fast `}<strong parentName="p">{`static-first React apps`}</strong>{` with Gatsby`}</p>
      <p>{`– Learn how to `}<strong parentName="p">{`use GraphQL`}</strong>{` to minimize API requests`}</p>
      <p>{`– Leverage `}<strong parentName="p">{`design systems`}</strong>{` to build beautiful apps without designers`}</p>
      <p>{`– `}<strong parentName="p">{`Serverless`}</strong>{` technologies for adding functionality on the server`}</p>
      <p>{`– `}<strong parentName="p">{`Store and manage data`}</strong>{` in a database`}</p>
      <p>{`– `}<strong parentName="p">{`Payments`}</strong>{` integration`}</p>
      <p>{`– `}<strong parentName="p">{`Authenticate`}</strong>{` users and keep track of their accounts`}</p>
      <p>{`– `}<strong parentName="p">{`JAMStack`}</strong>{` deployments for production-ready frontend code`}</p>
      <p>{`– The `}<strong parentName="p">{`Edge First Architecture`}</strong>{` for frontend state management using React Hooks`}</p>
    </Container>
    <Title h2 mdxType="Title">
## Start owning features like a pro
    </Title>
    <Container width={[1, 3 / 4, 1 / 2]} mb={[4, 4, 5]} sx={{
      textAlign: 'center'
    }} mdxType="Container">
      <p>{`Buy once forever yours.`}</p>
    </Container>
    <Container width={[1, 1, 2 / 3]} mdxType="Container">
  <Pricing mdxType="Pricing" />
    </Container>
    <Container width={[1, 3 / 4, 1 / 2]} mb={[4, 4, 5]} sx={{
      textAlign: 'center'
    }} mdxType="Container">
      <p>{`30 day moneyback guarantee.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      