import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Text } from "rebass";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reactathon"
        }}>{`Welcome to the workshop`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">The workshop</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reactathon/setup"
        }}>{`Project setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reactathon/core-functionality"
        }}>{`Add core functionality`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reactathon/authentication"
        }}>{`Add authentication`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reactathon/monetization"
        }}>{`Add monetization`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Before you head out</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reactathon/outro"
        }}>{`How was it?`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      