import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Text } from "rebass";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tia"
        }}>{`Intro`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tia/setup"
        }}>{`Project setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tia/tia-ui"
        }}>{`Styling with TiaUI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tia/queries"
        }}>{`Queries`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tia/routing"
        }}>{`Routing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tia/forms"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tia/fin"
        }}>{`Fin`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      