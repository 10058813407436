import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Text } from "rebass";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop"
        }}>{`Welcome to the workshop`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Before the workshop</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/intro"
        }}>{`Intro videos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/homework"
        }}>{`Homework setup`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">The workshop</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/hello-world"
        }}>{`Your first cloud function`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/accept-arguments"
        }}>{`Accepting arguments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/talk-to-api"
        }}>{`3rd party API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/chrome-puppeteer"
        }}>{`Chrome puppeteer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/file-to-s3"
        }}>{`Save file to S3`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/database"
        }}>{`Read/write a database`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/ui-integration"
        }}>{`Integrate with UI`}</a></li>
    </ul>
    <Text mt={3} mdxType="Text">Before you head out</Text>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-workshop/outro"
        }}>{`How was it?`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      